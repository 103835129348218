/**
 * @prettier
 */

import { observer } from 'mobx-react'

import { SelectWithSeparateSearch } from 'components/shared/select_with_separate_search'

import { flatOptions, getSelectedSiteName, getSites } from './helpers'
import {
  CreateSiteLink,
  formatGroupLabel,
  formatOptionLabel,
  Head,
  MenuDropdownStyleOverride,
  SiteSelectInner,
} from './components'

export const SitesSelector = observer(
  class SitesSelector extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        selectedOption: props.currentSiteCachedSlug,
      }
      this.dropDown = React.createRef()
    }

    componentDidUpdate(prevProps) {
      if (prevProps.currentSiteCachedSlug !== this.props.currentSiteCachedSlug) {
        this.setState({
          selectedOption: this.props.currentSiteCachedSlug,
        })
      }
    }

    handleChangeChosen = ({ value }) => {
      this.dropDown.current.toggleDropdownList()
      this.setState(
        {
          selectedOption: value,
        },
        () => {
          setTimeout(() => {
            const { selectedOption } = this.state
            const { goToAnotherSite } = this.props

            if (goToAnotherSite) {
              goToAnotherSite(selectedOption)
            } else {
              window.location = Routes.site_path(selectedOption)
            }
          }, 300)
        },
      )
    }

    getOptions = () => {
      const { currentUser, isSitePersisted, account } = this.props
      const sites = getSites(currentUser)
      const optionGroupOwn = {
        label: isSitePersisted ? 'My Sites' : _.get(account, 'name'),
        options: sites.own.map(item => ({
          label: item.name,
          value: item.cached_slug,
        })),
      }
      const optionGroupGranted = {
        label: isSitePersisted ? 'Granted Sites' : 'Other Sites',
        options: sites.granted.map(item => ({
          label: item.name,
          value: item.cached_slug,
        })),
      }

      return [optionGroupOwn, optionGroupGranted]
    }

    handleClickCreateSite = () => {
      this.dropDown.current.toggleDropdownList()
    }

    render() {
      const { isAdmin } = this.props
      const { selectedOption } = this.state
      const options = this.getOptions()

      return (
        <MenuDropdownStyleOverride
          data-testid="ac-header-site-selector"
          ref={this.dropDown}
          closeOnDropdownClick={false}
          renderButton={({ isOpened }) => (
            <Head isOpened={isOpened}>
              {getSelectedSiteName({ selectedOption, options })}
            </Head>
          )}
        >
          <SiteSelectInner>
            <SelectWithSeparateSearch
              isSearchable={flatOptions(options).length >= 5}
              menuIsOpen
              onChange={this.handleChangeChosen}
              options={options}
              placeholder="Search..."
              value={selectedOption}
              formatGroupLabel={formatGroupLabel}
              formatOptionLabel={formatOptionLabel}
              noOptionsMessage={() => 'No sites found'}
            />
          </SiteSelectInner>
          {isAdmin && <CreateSiteLink onClick={this.handleClickCreateSite} />}
        </MenuDropdownStyleOverride>
      )
    }
  },
)
