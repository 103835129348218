/**
 * @prettier
 */

import PT from 'prop-types'

import { Arrow, SubHeaderContainer, SubHeaderInner, SubHeaderLink } from '../index'
import {
  checkPathNameIncludesLink,
  shouldBeActiveReportsLink,
  shouldBeActiveCampaignsNavLink,
} from '../../helpers'

export class SubHeaderLoyalty extends React.PureComponent {
  static propTypes = {
    isSitePersisted: PT.bool.isRequired,
    isReadRole: PT.bool.isRequired,
    currentSiteCachedSlug: PT.string.isRequired,
    locationRouter: PT.object,
  }

  render() {
    const { isSitePersisted, isReadRole, currentSiteCachedSlug, locationRouter } =
      this.props

    return (
      isSitePersisted &&
      isReadRole && (
        <SubHeaderContainer data-testid="ac-subheader-loyalty-container">
          <div className="App-container-inner">
            <SubHeaderInner>
              <div className="flex align-items-center">
                <SubHeaderLink
                  isActive={
                    location.pathname === Routes.loyalty_site_path(currentSiteCachedSlug)
                  }
                  href={Routes.loyalty_site_path(currentSiteCachedSlug)}
                  locationRouter={locationRouter}
                >
                  Dashboard
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={shouldBeActiveCampaignsNavLink(
                    currentSiteCachedSlug,
                    'loyalty',
                  )}
                  href={Routes.campaigns_site_path(currentSiteCachedSlug, 'loyalty')}
                  locationRouter={locationRouter}
                >
                  Campaigns
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_loyalty_metrics_aggregation_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_loyalty_metrics_aggregation_path(
                    currentSiteCachedSlug,
                  )}
                >
                  Metrics
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_loyalty_members_spa_index_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_loyalty_members_spa_index_path(currentSiteCachedSlug)}
                  locationRouter={locationRouter}
                >
                  Members
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_loyalty_purchases_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_loyalty_purchases_path(currentSiteCachedSlug)}
                >
                  Purchases
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_coupon_lists_path(currentSiteCachedSlug, 'loyalty'),
                  )}
                  href={Routes.site_coupon_lists_path(currentSiteCachedSlug, 'loyalty')}
                >
                  Coupon Lists
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={shouldBeActiveReportsLink(currentSiteCachedSlug, 'loyalty')}
                  href={Routes.site_reports_path(currentSiteCachedSlug, 'loyalty')}
                >
                  All reports
                  <Arrow fill="#555" name="backArrow" />
                </SubHeaderLink>
              </div>
            </SubHeaderInner>
          </div>
        </SubHeaderContainer>
      )
    )
  }
}
