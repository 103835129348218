/**
 * @prettier
 */

import { HeaderLink } from '../index'

export class UnautorizedUserSubheader extends React.PureComponent {
  render() {
    return (
      <div className="flex" data-testid="ac-unautorized-user-subheader">
        <HeaderLink href="/" className="mll" height="54px">
          Home
        </HeaderLink>
        <HeaderLink href={Routes.walkthrough_path()} height="54px" className="mll">
          Learn More
        </HeaderLink>
        <HeaderLink
          href={documentationURL()}
          height="54px"
          target="_blank"
          className="mll"
        >
          Documentation
        </HeaderLink>
      </div>
    )
  }
}
