/**
 * @prettier
 */

import styled from 'styled-components'
import { Theme, UIDivider, UIIcon, UILink, UIMenuDropdown } from 'talkable-ui-kit'

export const MenuDropdownStyleOverride = styled(UIMenuDropdown)`
  z-index: 4;
  margin-top: 3px;
  ${UIMenuDropdown.CLASS_NAMES.list} {
    right: unset;
    left: 0;
  }
`

export const SiteSelectInner = styled.div`
  input {
    box-shadow: none !important;
    line-height: unset;
    display: inline-block;
    height: auto;
    padding: 0;
    margin-bottom: 0;
    font-size: inherit;
    color: unset;
    border-radius: 0;
    vertical-align: middle;
  }
`

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const Text = styled.div`
  ${Theme.fontWeights.medium};
  color: ${({ color }) => color};
  font-size: 14px;
`

const HeadText = styled(Text)`
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
  color: #555555;
`

export const formatGroupLabel = data => (
  <div style={groupStyles}>
    <Text color="#999999">{data.label}</Text>
  </div>
)

export const formatOptionLabel = data => (
  <Text color={Theme.color.black100}>{data.label}</Text>
)

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Head = ({ children }) => (
  <HeadContainer>
    <HeadText>{children}</HeadText>
    <UIIcon name="downArrow" fill="#555555" />
  </HeadContainer>
)

const CreateSiteLinkStyleOverride = styled(UILink)`
  padding: 12px;
  .sign-svg-fill {
    fill: ${Theme.color.link};
  }
`

export const CrateSiteWrapper = styled.div`
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
  margin-top: -1px;
`
export const CreateSiteLink = ({ onClick }) => (
  <CrateSiteWrapper>
    <UIDivider />
    <CreateSiteLinkStyleOverride onClick={onClick} href={Routes.new_account_site_path()}>
      <UIIcon fill={Theme.color.link} name="create" />
      Create site
    </CreateSiteLinkStyleOverride>
  </CrateSiteWrapper>
)
