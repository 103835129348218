/**
 * @prettier
 */
import styled from 'styled-components'
import PT from 'prop-types'
import { UIIcon, UILink, Theme } from 'talkable-ui-kit'
import { inject, observer } from 'mobx-react'

import { checkRole, getSiteSpace } from 'utils/etc'
import { HEADER_GA_CATEGORY } from 'utils/constants'

import { MainHeaderUserMenu } from './components/heder_menu/header_user_menu'
import {
  getCurrentAccessibleSite,
  getCurrentSiteCachedSlug,
  getUrlHome,
  shouldBeActiveCspTab,
  shouldShowSiteSelector,
} from './helpers'
import { MainHeaderStaffMenu } from './components/heder_menu/header_staff_menu'
import {
  HeaderTopContainer,
  HeaderLink,
  VerticalDivider,
  HeaderContainer,
  HeaderTopInner,
} from './components'
import { SubHeaderReferral } from './components/subheaders/subheader_referral'
import { HeaderHomeLink } from './components/header_home_link'
import { HeaderProductSwitcher } from './components/header_product_switcher'
import { UnautorizedUserSubheader } from './components/subheaders/subheader_unautorized_user'
import { SitesSelector } from './components/site_selector/sites_selector'
import { SubHeaderConversion } from './components/subheaders/subheader_conversion'
import { SubHeaderLoyalty } from './components/subheaders/subheader_loyalty'

const LinkHelp = styled(UILink)`
  && {
    line-height: 1;
    color: #555;
    transition: color 0.3s;

    svg {
      .sign-svg-fill-outer {
        transition: 0.3s;
      }
    }

    &:focus,
    &:hover,
    &:active {
      color: ${Theme.color.black100};

      .sign-svg-fill-outer {
        fill: #30a32f;
      }
    }
  }
`

export const Header = inject(({ rootStore }) => ({
  rootStore,
}))(
  observer(
    class Header extends React.PureComponent {
      static propTypes = {
        site: PT.object,
        currentUser: PT.object,
        goToAnotherSite: PT.func,
        locationRouter: PT.object,
      }

      render() {
        const {
          currentUser,
          site,
          account,
          locationRouter,
          accountCurrentSite,
          goToAnotherSite,
          className,
          rootStore,
          isDisabledView,
        } = this.props

        const currentSiteCachedSlug = getCurrentSiteCachedSlug({
          site,
          accountCurrentSite,
          currentUser,
        })
        const isSitePersisted = Boolean(currentSiteCachedSlug)
        const isReadRole =
          currentUser &&
          checkRole(
            currentUser.staff_member,
            getCurrentAccessibleSite(currentUser.accessible_sites, currentSiteCachedSlug),
            'read',
          )
        const isWriteRole =
          currentUser &&
          checkRole(
            currentUser.staff_member,
            getCurrentAccessibleSite(currentUser.accessible_sites, currentSiteCachedSlug),
            'write',
          )
        const isAdmin =
          currentUser &&
          checkRole(
            currentUser.staff_member,
            getCurrentAccessibleSite(currentUser.accessible_sites, currentSiteCachedSlug),
            'admin',
          )
        const isStaffMember = currentUser?.staff_member
        const isPermissionManager = currentUser?.permission_manager
        const urlHome = getUrlHome({
          currentSiteCachedSlug,
          currentUser,
        })
        const space = rootStore?.space || getSiteSpace(location.pathname)
        const showCspLink = isSitePersisted && space === 'referral'
        const showLoyaltySubHeader =
          isStaffMember || site?.grant_access_to_loyalty === true

        if (isDisabledView) {
          return null
        }

        return (
          <HeaderContainer
            className={classNames('header', className)}
            data-testid="ac-header"
          >
            <HeaderTopContainer>
              <div className="App-container-inner">
                <HeaderTopInner
                  className={classNames(
                    'flex justify-space-between align-items-center',
                    className,
                  )}
                >
                  <div className="flex align-items-center">
                    <HeaderHomeLink locationRouter={locationRouter} urlHome={urlHome} />
                    {shouldShowSiteSelector({ currentUser, locationRouter }) && (
                      <div className="mll">
                        <SitesSelector
                          goToAnotherSite={goToAnotherSite}
                          site={site}
                          currentUser={currentUser}
                          account={account}
                          isSitePersisted={isSitePersisted}
                          isAdmin={isAdmin}
                          currentSiteCachedSlug={currentSiteCachedSlug}
                        />
                      </div>
                    )}
                    {isSitePersisted && currentUser && isReadRole && (
                      <HeaderProductSwitcher
                        site={site}
                        currentUser={currentUser}
                        currentSiteCachedSlug={currentSiteCachedSlug}
                        locationRouter={locationRouter}
                        urlHome={urlHome}
                        showLoyaltySubHeader={showLoyaltySubHeader}
                      />
                    )}
                  </div>
                  {currentUser ? (
                    <div
                      className="flex align-items-center"
                      data-testid="ac-header-csp-link"
                    >
                      {showCspLink && (
                        <HeaderLink
                          className="mrl"
                          isActive={shouldBeActiveCspTab()}
                          href={Routes.customer_service_portal_person_lookup_site_path(
                            currentSiteCachedSlug,
                          )}
                          type="customerServicePortal"
                          locationRouter={locationRouter}
                        >
                          <UIIcon name="manCircled" className="mrd" />
                          <span>Customer service portal</span>
                        </HeaderLink>
                      )}
                      <LinkHelp
                        href="https://talkable.freshdesk.com/support/solutions"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        data-category={HEADER_GA_CATEGORY}
                        data-action="click to help in header"
                        className="mrl js-ga-click"
                      >
                        <UIIcon
                          className="mrx"
                          name="questionCircleWithBorder"
                          fill="#555"
                        />
                        <span>Help</span>
                      </LinkHelp>
                      <VerticalDivider />
                      <div className="mlm">
                        <MainHeaderUserMenu
                          account={account}
                          currentUser={currentUser}
                          site={site}
                          currentSiteCachedSlug={currentSiteCachedSlug}
                          locationRouter={locationRouter}
                        />
                      </div>
                    </div>
                  ) : (
                    <UnautorizedUserSubheader />
                  )}
                </HeaderTopInner>
              </div>
              {(isStaffMember || isPermissionManager) && (
                <MainHeaderStaffMenu isPermissionManager={isPermissionManager} />
              )}
            </HeaderTopContainer>
            {isSitePersisted && currentUser && _.some(site) && (
              <>
                {space === 'conversion' && (
                  <SubHeaderConversion
                    currentSiteCachedSlug={currentSiteCachedSlug}
                    isSitePersisted={isSitePersisted}
                    isReadRole={isReadRole}
                    locationRouter={locationRouter}
                  />
                )}
                {space === 'referral' && (
                  <SubHeaderReferral
                    currentSiteCachedSlug={currentSiteCachedSlug}
                    isSitePersisted={isSitePersisted}
                    isReadRole={isReadRole}
                    isWriteRole={isWriteRole}
                    locationRouter={locationRouter}
                    videoEnabled={site?.feature_flags?.offer_videos_liquid}
                  />
                )}
                {space === 'loyalty' && showLoyaltySubHeader && (
                  <SubHeaderLoyalty
                    currentSiteCachedSlug={currentSiteCachedSlug}
                    isSitePersisted={isSitePersisted}
                    isReadRole={isReadRole}
                    isWriteRole={isWriteRole}
                    locationRouter={locationRouter}
                  />
                )}
              </>
            )}
          </HeaderContainer>
        )
      }
    },
  ),
)
