/**
 * @prettier
 */
import PT from 'prop-types'
import { UIIcon } from 'talkable-ui-kit'
import { inject, observer } from 'mobx-react'

import { getSiteSpace, isReferralSpace } from 'utils/etc'

import { HeaderLink, VerticalDivider, BetaLabel } from './index'

export const HeaderProductSwitcher = inject(({ rootStore }) => ({
  rootStore,
}))(
  observer(
    class HeaderProductSwitcher extends React.PureComponent {
      static propTypes = {
        currentSiteCachedSlug: PT.string.isRequired,
        urlHome: PT.string.isRequired,
        locationRouter: PT.object,
      }

      render() {
        const { currentSiteCachedSlug, urlHome, locationRouter, showLoyaltySubHeader } =
          this.props

        const space = getSiteSpace(location.pathname)

        if (!showLoyaltySubHeader) {
          return null
        }

        return (
          <div
            className="flex align-items-center mlm"
            data-testid="ac-header-product-switcher"
          >
            <VerticalDivider />
            <HeaderLink
              className="mlm"
              isActive={isReferralSpace(location.pathname)}
              href={urlHome}
              type="referral"
              locationRouter={locationRouter}
            >
              <UIIcon name="horn" className="mrd" />
              <span>Referrals</span>
            </HeaderLink>
            <HeaderLink
              className="mll"
              isActive={space === 'conversion'}
              href={Routes.conversion_site_path(currentSiteCachedSlug)}
              type="emailCapture"
              locationRouter={locationRouter}
            >
              <UIIcon name="emailCapture" className="mrd" />
              <span>Conversion</span>
              <BetaLabel>beta</BetaLabel>
            </HeaderLink>
            <HeaderLink
              className="mll"
              isActive={space === 'loyalty'}
              href={Routes.loyalty_site_path(currentSiteCachedSlug)}
              type="loyaltyBadge"
              locationRouter={locationRouter}
            >
              <UIIcon name="loyaltyBadge" className="mrd" />
              <span>Loyalty</span>
              <BetaLabel>beta</BetaLabel>
            </HeaderLink>
          </div>
        )
      }
    },
  ),
)
