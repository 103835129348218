/**
 * @prettier
 */

import qs from 'query-string'

import { checkRole, publicUrlOptionsHost } from 'utils/etc'

export const isReadRole = (currentUser, currentSiteCachedSlug) =>
  currentUser &&
  checkRole(
    currentUser.staff_member,
    getCurrentAccessibleSite(currentUser.accessible_sites, currentSiteCachedSlug),
    'read',
  )

export const getUrlHome = ({ currentSiteCachedSlug, currentUser }) => {
  if (!currentUser) {
    return publicUrlOptionsHost() || '/'
  }

  if (!currentSiteCachedSlug) return ''

  return isReadRole(currentUser, currentSiteCachedSlug)
    ? Routes.site_path(currentSiteCachedSlug)
    : Routes.customer_service_portal_person_lookup_site_path(currentSiteCachedSlug)
}

export const shouldBeActivePurchaseNavLink = (currentSiteCachedSlug, space = '') => {
  const originType = qs.parse(window.location.search)['origin[type]']
  const invalidOriginTypes = ['AffiliateMember', 'Event']

  return (
    originType === 'Purchase' ||
    (!invalidOriginTypes.includes(originType) &&
      checkPathNameIncludesLink(Routes.site_origins_path(currentSiteCachedSlug, space)))
  )
}

export const shouldBeActiveCampaignsNavLink = (currentSiteCachedSlug, space = '') => {
  return (
    checkPathNameIncludesLink(Routes.site_campaigns_path(currentSiteCachedSlug, space)) ||
    qs.parse(window.location.search)['origin[type]'] === 'AffiliateMember'
  )
}

export const getCurrentAccessibleSite = (accessibleSites, cachedSlug) => {
  return accessibleSites.find(accessibleSite => accessibleSite.cached_slug === cachedSlug)
}

export const shouldBeActiveReportsLink = (currentSiteCachedSlug, space = '') => {
  let configurationLinks = [
    Routes.site_static_assets_path(currentSiteCachedSlug, space),
    Routes.site_personal_coupon_lists_path(currentSiteCachedSlug, space),
    Routes.locale_entries_site_reports_path(currentSiteCachedSlug, space),
    Routes.site_settings_changes_path(currentSiteCachedSlug, space),
    Routes.site_previous_customers_path(currentSiteCachedSlug, space),
    Routes.site_event_uploads_path(currentSiteCachedSlug, space),
    Routes.site_secured_files_path(currentSiteCachedSlug, space),
  ]
  let supportLinks = [
    Routes.site_origins_path(currentSiteCachedSlug, space),
    Routes.site_visitor_offers_path(currentSiteCachedSlug, space),
  ]

  if (!space) {
    configurationLinks = [
      ...configurationLinks,
      Routes.site_coupon_lists_path(currentSiteCachedSlug, space),
    ]

    supportLinks = [
      ...supportLinks,
      Routes.site_customer_emails_path(currentSiteCachedSlug, space),
    ]
  }

  const staffOnlyReportsLinks = [
    Routes.site_page_snapshots_path(currentSiteCachedSlug, space),
  ]
  const reportsLinks = [Routes.site_split_tests_path(currentSiteCachedSlug, space)]
  const links = [
    ...configurationLinks,
    ...supportLinks,
    ...reportsLinks,
    ...staffOnlyReportsLinks,
  ]

  return (
    (checkPathNameIncludesLink(Routes.site_reports_path(currentSiteCachedSlug, space)) &&
      !checkPathNameIncludesLink(
        Routes.site_reports_metrics_aggregation_path(currentSiteCachedSlug, space),
      ) &&
      !checkPathNameIncludesLink(
        Routes.offer_videos_site_reports_path(currentSiteCachedSlug),
      )) ||
    (links.some(link => checkPathNameIncludesLink(link)) &&
      !shouldBeActivePurchaseNavLink(currentSiteCachedSlug, space) &&
      !shouldBeActiveCampaignsNavLink(currentSiteCachedSlug, space))
  )
}

export const checkPathNameIncludesLink = link => {
  return location.pathname.includes(link)
}

export const shouldShowSiteSelector = ({ currentUser, locationRouter }) => {
  return (
    currentUser &&
    !_.startsWith(_.get(locationRouter, 'pathname') || location.pathname, '/staff')
  )
}

export const shouldBeActiveCspTab = () => {
  return checkPathNameIncludesLink(Routes.customer_service_portal_path())
}

export const getCurrentSiteCachedSlug = ({ site, accountCurrentSite, currentUser }) => {
  return (
    site?.cached_slug ||
    accountCurrentSite?.cached_slug ||
    currentUser?.current_site_slug ||
    Utils?.getCurrentSite()
  )
}
