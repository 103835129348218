/**
 * @prettier
 */

import PT from 'prop-types'
import styled, { css } from 'styled-components'
import { Theme, UIMenuDropdown, BodyD } from 'talkable-ui-kit'
import { Link } from 'react-router-dom'

export const HeaderUserMenuItemStyleOverride = styled(UIMenuDropdown.Item)`
  && {
    padding: 0;
    min-width: 200px;
    display: flex;
    align-items: center;
    max-width: 240px;
    & > a {
      padding: 12px 20px;
      ${({ isSmall }) =>
        isSmall &&
        css`
          padding: 10px 12px;
        `};
      width: 100%;
      color: ${({ color }) => color || '#555555'};
      display: flex;
      ${Theme.fontWeights.medium};
      ${({ withDescription }) =>
        withDescription
          ? css`
              ${IconWrap} {
                margin-top: 3px;
              }
            `
          : css`
              align-items: center;
            `};
      &:hover {
        ${({ hoveredColor, iconHoveredColor }) => css`
          color: ${hoveredColor || Theme.color.black100};
          .sign-svg-fill {
            fill: ${iconHoveredColor || hoveredColor || Theme.color.black100};
          }
        `};
      }
    }
    span {
      white-space: normal;
    }
    &:first-child {
      border-top: none;
    }
  }
`

const LinkStyleOverride = styled.div`
  display: block;
`

export const IconWrap = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 8px;
  svg {
    margin: 0;
  }
  ${({ isSmall }) =>
    isSmall &&
    css`
      width: 16px;
      height: 16px;
    `}
`

export class HeaderUserMenuItem extends React.PureComponent {
  static propTypes = {
    icon: PT.func.isRequired,
  }

  checkActive = (href, linksActive) => {
    const { locationRouter, excludeLinks } = this.props

    if (
      excludeLinks &&
      excludeLinks.some(link =>
        (_.get(locationRouter, 'pathname') || location.pathname).includes(link),
      )
    ) {
      return false
    }

    if (Array.isArray(linksActive)) {
      return linksActive.some(link => this.checkActive(link))
    }

    return _.startsWith(
      _.get(locationRouter, 'pathname') || location.pathname,
      href.split('?')[0],
    )
  }

  render() {
    const {
      href,
      locationRouter,
      isRouterLink,
      name,
      linksActive,
      icon,
      color,
      hoveredColor,
      iconHoveredColor,
      description,
      isSmall,
    } = this.props

    return (
      <HeaderUserMenuItemStyleOverride
        color={color}
        hoveredColor={hoveredColor}
        iconHoveredColor={iconHoveredColor}
        isActive={this.checkActive(href, linksActive)}
        isLink
        withDescription={description}
        isSmall={isSmall}
      >
        <LinkStyleOverride
          to={locationRouter && isRouterLink ? href : undefined}
          href={!locationRouter || !isRouterLink ? href : undefined}
          as={locationRouter && isRouterLink ? Link : 'a'}
        >
          <IconWrap isSmall={isSmall}>{icon()}</IconWrap>
          <span>
            {name}
            {description && (
              <>
                <br />
                <BodyD color={Theme.color.black75}>{description}</BodyD>
              </>
            )}
          </span>
        </LinkStyleOverride>
      </HeaderUserMenuItemStyleOverride>
    )
  }
}
