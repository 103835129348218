/**
 * @prettier
 */

export const flatOptions = options => {
  return options.reduce((result, item) => [...result, ...item.options], [])
}

export const getSites = currentUser => {
  const accessibleSites = _.get(currentUser, 'accessible_sites', [])
  const sortedAccessibleSites = _.orderBy(accessibleSites, ['name'], ['asc'])
  const sites = {
    own: [],
    granted: [],
  }

  return sortedAccessibleSites.reduce((acc, item) => {
    const accountId = currentUser.account_id || _.get(window, 'Account.id')

    if (item.account_id === accountId) {
      sites.own.push(item)
    } else {
      sites.granted.push(item)
    }

    return sites
  }, sites)
}

export const getSelectedSiteName = ({ selectedOption, options }) => {
  return flatOptions(options).find(item => item.value === selectedOption)?.label
}
