/**
 * @prettier
 */
import $ from 'jquery'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'

import { defaultUrlOptionsHost } from 'utils/etc'

export const getHeaders = (headers = {}) => ({
  ...headers,
  'X-CSRF-Token': $('meta[name=csrf-token]').attr('content'),
})

export const getUrl = (url = '') => `${defaultUrlOptionsHost()}${url}`

export const jsonToFormData = data => {
  const buildFormData = (formData, data, parentKey) => {
    if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      if (Array.isArray(data)) {
        data.forEach(value => {
          buildFormData(formData, value, parentKey ? `${parentKey}[]` : `[]`)
        })

        if (!data.length) {
          formData.append(parentKey ? `${parentKey}[]` : `[]`, [])
        }
      } else {
        Object.keys(data).forEach(key => {
          buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
      }
    } else {
      const value = data === null ? '' : data

      formData.append(parentKey, value)
    }
  }

  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}

export const ajax = ({ url = '', headers = {}, withFileUpload = false, ...options }) => {
  let params = {
    ...options,
    headers: getHeaders(headers),
    url: getUrl(url),
    xhrFields: { withCredentials: true },
    crossDomain: true,
  }

  if (withFileUpload) {
    params = {
      ...params,
      processData: false,
      contentType: false,
      data: jsonToFormData(params.data),
    }
  }

  return $.ajax(params)
}

export const isAccessDeniedStatus = xhr =>
  (xhr.status === 401 && get(xhr, 'responseJSON.errors.0') === 'Unauthorized') ||
  (xhr.status === 401 && get(xhr, 'responseJSON.result.errors.0') === 'Unauthorized') ||
  (xhr.status === 401 &&
    get(xhr, 'responseJSON.result.error_message') === 'Unauthorized') ||
  xhr.status === 403

export const notifyDevelopers = params => {
  const { name, message, xhr, custom } = params

  if (!name || !xhr) {
    console.error('name and xhr attributes must be specified')
  }

  if (xhr?.statusText === 'abort') {
    return 'xhr was aborted'
  }

  if (window.Bugsnag) {
    Bugsnag.notify({ name, message }, event => {
      event.addMetadata('custom', {
        xhr,
        requestId:
          xhr &&
          isFunction(xhr.getResponseHeader) &&
          xhr.getResponseHeader('x-request-id'),
        custom,
      })
    })
  } else {
    console.error(params)
  }
}
