/**
 * @prettier
 */

import PT from 'prop-types'

import { Arrow, SubHeaderContainer, SubHeaderInner, SubHeaderLink } from '../index'
import {
  checkPathNameIncludesLink,
  shouldBeActivePurchaseNavLink,
  shouldBeActiveReportsLink,
} from '../../helpers'

export class SubHeaderReferral extends React.PureComponent {
  static propTypes = {
    isSitePersisted: PT.bool.isRequired,
    isReadRole: PT.bool.isRequired,
    currentSiteCachedSlug: PT.string.isRequired,
    videoEnabled: PT.bool,
    locationRouter: PT.object,
  }

  render() {
    const {
      isSitePersisted,
      isReadRole,
      isWriteRole,
      currentSiteCachedSlug,
      locationRouter,
      videoEnabled,
    } = this.props

    return (
      <SubHeaderContainer data-testid="ac-subheader-referral-container">
        <div className="App-container-inner">
          <SubHeaderInner>
            <div className="flex align-items-center">
              {isSitePersisted && isReadRole && (
                <SubHeaderLink
                  isActive={location.pathname === Routes.site_path(currentSiteCachedSlug)}
                  href={Routes.site_path(currentSiteCachedSlug)}
                  locationRouter={locationRouter}
                >
                  Dashboard
                </SubHeaderLink>
              )}
              {isSitePersisted && isReadRole && (
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_campaigns_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_campaigns_path(currentSiteCachedSlug)}
                  locationRouter={locationRouter}
                >
                  Campaigns
                </SubHeaderLink>
              )}
              {isReadRole && (
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_reports_metrics_aggregation_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_reports_metrics_aggregation_path(
                    currentSiteCachedSlug,
                  )}
                >
                  Metrics
                </SubHeaderLink>
              )}
              {isSitePersisted && isReadRole && (
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_offers_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_offers_path(currentSiteCachedSlug)}
                >
                  Offers
                </SubHeaderLink>
              )}
              {isSitePersisted && isReadRole && (
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_offer_shares_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_offer_shares_path(currentSiteCachedSlug)}
                >
                  Shares
                </SubHeaderLink>
              )}
              {isSitePersisted && isReadRole && (
                <SubHeaderLink
                  className="mll"
                  isActive={shouldBeActivePurchaseNavLink(currentSiteCachedSlug)}
                  href={Routes.site_purchases_path(currentSiteCachedSlug)}
                >
                  Purchases
                </SubHeaderLink>
              )}
              {isSitePersisted && isReadRole && (
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_referrals_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_referrals_path(currentSiteCachedSlug)}
                >
                  Referrals
                </SubHeaderLink>
              )}
              {isSitePersisted && isReadRole && (
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_incentive_outcomes_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_incentive_outcomes_path(currentSiteCachedSlug)}
                >
                  Rewards
                </SubHeaderLink>
              )}
              {isSitePersisted && isReadRole && (
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_people_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_people_path(currentSiteCachedSlug)}
                >
                  People
                </SubHeaderLink>
              )}
              {isSitePersisted && isReadRole && videoEnabled && (
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.offer_videos_site_reports_path(currentSiteCachedSlug),
                  )}
                  href={Routes.offer_videos_site_reports_path(currentSiteCachedSlug)}
                >
                  Video
                </SubHeaderLink>
              )}
              {isSitePersisted && isReadRole && (
                <SubHeaderLink
                  className="mll"
                  isActive={shouldBeActiveReportsLink(currentSiteCachedSlug)}
                  href={Routes.site_reports_path(currentSiteCachedSlug)}
                >
                  All reports
                  <Arrow fill="#555555" name="backArrow" />
                </SubHeaderLink>
              )}
            </div>
            {isSitePersisted && isWriteRole && (
              <div>
                <SubHeaderLink
                  className="mls"
                  isActive={checkPathNameIncludesLink(
                    Routes.edit_site_path(currentSiteCachedSlug, 'referral_attribution'),
                  )}
                  href={Routes.edit_site_path(
                    currentSiteCachedSlug,
                    'referral_attribution',
                  )}
                  locationRouter={locationRouter}
                >
                  Referral attribution
                </SubHeaderLink>
              </div>
            )}
          </SubHeaderInner>
        </div>
      </SubHeaderContainer>
    )
  }
}
