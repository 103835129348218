/**
 * @prettier
 */
import { Link } from 'react-router-dom'

import { integrationPath, checkRole } from 'utils/etc'
import { getCurrentAccessibleSite } from 'components/shared/header/helpers'

export class Footer extends React.PureComponent {
  render() {
    const { site, currentUser, isSpa } = this.props

    return (
      <div className="footer">
        <div className="App-container-inner">
          <div className="footer-copy">&copy; {dayjs().format('YYYY')} Talkable Inc</div>
          <div className="footer-links">
            {isSpa ? (
              <Link to={Routes.site_path(_.get(site, 'cached_slug'))}>Dashboard</Link>
            ) : (
              <a
                href={
                  _.get(site, 'id')
                    ? Routes.site_path(_.get(site, 'cached_slug'))
                    : window.location.origin
                }
              >
                Dashboard
              </a>
            )}
            {currentUser &&
              checkRole(
                currentUser.staff_member,
                getCurrentAccessibleSite(currentUser.accessible_sites, site?.cached_slug),
                'write',
              ) &&
              (_.get(site, 'id') ? (
                <React.Fragment>
                  <a
                    href={integrationPath({
                      siteCachedSlug: site.cached_slug,
                      platform: site.platform,
                    })}
                  >
                    Integration Guide
                  </a>
                  <a
                    href={documentationURL()}
                    className="api_nav"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Documentation
                  </a>
                </React.Fragment>
              ) : (
                <a
                  href={documentationURL()}
                  className="integration_nav"
                  target="_blank"
                  rel="noreferrer"
                >
                  Integration Guide
                </a>
              ))}
            <a href="https://www.talkable.com/privacy" className="privacy_nav">
              Privacy
            </a>
            <a href="https://www.talkable.com/jobs" className="careers_nav">
              Careers
            </a>
          </div>
        </div>
      </div>
    )
  }
}
