/**
 * @prettier
 */
import PT from 'prop-types'
import { Theme, UIDivider, UIIcon, UIMenuDropdown } from 'talkable-ui-kit'
import styled, { css } from 'styled-components'

import {
  getMenuIntegrationName,
  getMenuIntegrationIcon,
  integrationPath,
  checkRole,
} from 'utils/etc'

import { HeaderUserMenuItem } from './header_menu_item'
import { getCurrentAccessibleSite, getCurrentSiteCachedSlug } from '../../helpers'

export const MenuIconWrapper = styled.div`
  border-radius: 4px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}
  svg {
    margin: 0 !important;
  }
  ${({ isOpened }) =>
    isOpened &&
    css`
      background-color: ${Theme.color.black25};
      svg {
        width: 12px;
        height: 12px;
      }
    `}
`

const DividerWrapper = styled.div`
  margin: 0 10px;
`

const UserMenuDropDown = styled(UIMenuDropdown)`
  ${UIMenuDropdown.CLASS_NAMES.list} {
    width: 235px;
    z-index: 5;
  }
`

export class MainHeaderUserMenu extends React.PureComponent {
  static propTypes = {
    site: PT.object,
    account: PT.object,
    locationRouter: PT.object,
    editor: PT.bool,
    currentUser: PT.object.isRequired,
  }

  static defaultProps = {
    editor: false,
  }

  render() {
    const { site, account, locationRouter, currentUser, editor } = this.props

    const currentSiteCachedSlug = getCurrentSiteCachedSlug({
      site,
      currentUser,
    })
    const accountId = account?.id
    const isSitePersisted = Boolean(site?.id) || Boolean(currentSiteCachedSlug)
    const isAdmin = checkRole(
      currentUser.staff_member,
      getCurrentAccessibleSite(currentUser.accessible_sites, currentSiteCachedSlug),
      'admin',
    )
    const isWrite = checkRole(
      currentUser.staff_member,
      getCurrentAccessibleSite(currentUser.accessible_sites, currentSiteCachedSlug),
      'write',
    )
    const isRead = checkRole(
      currentUser.staff_member,
      getCurrentAccessibleSite(currentUser.accessible_sites, currentSiteCachedSlug),
      'read',
    )

    return (
      <UserMenuDropDown
        renderButton={({ isOpened }) => (
          <MenuIconWrapper isOpened={isOpened}>
            <UIIcon
              name={isOpened ? 'cross' : 'menu'}
              fill={editor && !isOpened ? Theme.color.black25 : Theme.color.black100}
            />
          </MenuIconWrapper>
        )}
      >
        {isSitePersisted && isRead && (
          <>
            <HeaderUserMenuItem
              name="All site settings"
              description={isWrite ? 'Domains, referral attribution' : ''}
              icon={() => (
                <UIIcon
                  fill={Theme.color.black75}
                  name="siteSettings"
                  stroke={Theme.color.black15}
                />
              )}
              href={Routes.edit_site_path(currentSiteCachedSlug)}
              excludeLinks={[
                Routes.app_store_site_path(currentSiteCachedSlug),
                Routes.edit_site_path(currentSiteCachedSlug, 'placements'),
              ]}
              locationRouter={locationRouter}
              isRouterLink
            />
            {isWrite && (
              <HeaderUserMenuItem
                name="App store"
                icon={() => (
                  <UIIcon
                    fill={Theme.color.black75}
                    name="appStore"
                    stroke={Theme.color.black15}
                  />
                )}
                href={Routes.app_store_site_path(currentSiteCachedSlug)}
                locationRouter={locationRouter}
                isRouterLink
              />
            )}
            <HeaderUserMenuItem
              name="Placements"
              icon={() => (
                <UIIcon
                  fill={Theme.color.black75}
                  name="sitePlacements"
                  stroke={Theme.color.black15}
                />
              )}
              href={Routes.edit_site_path(currentSiteCachedSlug, 'placements')}
              locationRouter={locationRouter}
              isRouterLink
            />
          </>
        )}
        {isSitePersisted && isWrite && (
          <>
            <HeaderUserMenuItem
              name="Fraud settings"
              icon={() => <UIIcon fill={Theme.color.black75} name="fraudSettings" />}
              href={Routes.fraud_site_path(currentSiteCachedSlug)}
              locationRouter={locationRouter}
              isRouterLink
            />
            <HeaderUserMenuItem
              name="Webhooks"
              icon={() => <UIIcon fill={Theme.color.black75} name="webhook" />}
              href={Routes.site_web_hooks_path(currentSiteCachedSlug)}
              locationRouter={locationRouter}
              isRouterLink
            />
            {_.some(site) && (
              <HeaderUserMenuItem
                name={getMenuIntegrationName(site.platform)}
                icon={() => (
                  <UIIcon
                    width="24"
                    height="24"
                    fill={Theme.color.black75}
                    name={getMenuIntegrationIcon(site.platform)}
                  />
                )}
                linksActive={[
                  Routes.integration_site_path(currentSiteCachedSlug),
                  Routes.integration_shopify_site_path(currentSiteCachedSlug),
                ]}
                href={integrationPath({
                  siteCachedSlug: currentSiteCachedSlug,
                  platform: site.platform,
                })}
                locationRouter={locationRouter}
                isRouterLink
              />
            )}
            <DividerWrapper>
              <UIDivider />
            </DividerWrapper>
          </>
        )}
        {isSitePersisted && isAdmin && (
          <HeaderUserMenuItem
            name="Account settings"
            description="Blocklist, QA IPs & emails, account security, single sign-on"
            icon={() => <UIIcon fill={Theme.color.black75} name="accountSettings" />}
            href={Routes.edit_account_settings_site_path(
              currentSiteCachedSlug,
              'general',
            )}
            locationRouter={locationRouter}
          />
        )}
        {(currentUser.staff_member || currentUser.account_admin) && (
          <HeaderUserMenuItem
            name="Users & privileges"
            iconName="users"
            icon={() => <UIIcon fill={Theme.color.black75} name="users" />}
            href={Routes.account_users_path(accountId)}
          />
        )}
        {isRead && (
          <HeaderUserMenuItem
            name="Metrics glossary"
            href={Routes.account_metrics_path(accountId)}
            icon={() => <UIIcon fill={Theme.color.black75} name="metrics" />}
          />
        )}
        {isRead && (
          <HeaderUserMenuItem
            name="Pay bill"
            icon={() => <UIIcon fill={Theme.color.black75} name="pay" />}
            href={Routes.new_account_charge_path(accountId)}
          />
        )}
        <HeaderUserMenuItem
          isExact
          name="Sites"
          icon={() => <UIIcon fill={Theme.color.black75} name="sites" />}
          href={Routes.account_sites_path(accountId)}
        />
        <DividerWrapper>
          <UIDivider />
        </DividerWrapper>
        <HeaderUserMenuItem
          name="My profile"
          icon={() => <UIIcon fill={Theme.color.black75} name="user" />}
          href={Routes.edit_user_path()}
          locationRouter={locationRouter}
        />
        <HeaderUserMenuItem
          name="Refer a friend"
          icon={() => <UIIcon name="giftLarge" stroke={Theme.color.link} />}
          color={Theme.color.link}
          hoveredColor="#005db5"
          href={Routes.invite_user_path()}
          locationRouter={locationRouter}
        />
        <HeaderUserMenuItem
          name="Logout"
          color={Theme.color.red}
          hoveredColor="#cb030d"
          icon={() => <UIIcon fill={Theme.color.red} name="logout" />}
          href={Routes.logout_path()}
        />
      </UserMenuDropDown>
    )
  }
}
