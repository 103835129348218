/**
 * @prettier
 */

import PT from 'prop-types'
import styled, { css } from 'styled-components'
import { UIIcon, Theme, UIMenuDropdown, UIDivider } from 'talkable-ui-kit'

import { HeaderUserMenuItem } from './header_menu_item'

const UIMenuDropdownStyleOverride = styled(UIMenuDropdown)`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 32px;
  ${UIMenuDropdown.CLASS_NAMES.list} {
    min-width: 240px;
    margin-right: 16px;
    z-index: 5;
  }
`

export const StaffMenuIconWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #ffde00;
  svg {
    margin: 0 !important;
  }
  ${({ isOpened }) =>
    isOpened &&
    css`
      svg {
        width: 12px;
        height: 12px;
      }
    `}
`

const MenuItemTitle = styled(UIMenuDropdown.GroupTitle)`
  padding: 10px 12px;
  color: ${Theme.color.black50};
`

const HeaderUserMenuItemStaff = props => <HeaderUserMenuItem isSmall {...props} />

export class MainHeaderStaffMenu extends React.Component {
  static propTypes = {
    isPermissionManager: PT.bool,
  }

  static defaultProps = {
    isPermissionManager: false,
  }

  render() {
    const { isPermissionManager } = this.props

    return (
      <UIMenuDropdownStyleOverride
        data-testid="ac-header-staff-menu"
        renderButton={({ isOpened, disabled }) => (
          <StaffMenuIconWrapper
            className="staff-menu-icon-wrapper"
            disabled={disabled}
            isOpened={isOpened}
          >
            <UIIcon
              name={isOpened ? 'cross' : 'lockFilled'}
              fill={Theme.color.black100}
            />
          </StaffMenuIconWrapper>
        )}
      >
        {isPermissionManager && (
          <>
            <HeaderUserMenuItemStaff
              name="Access"
              href={Routes.account_accesses_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="events" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="All users"
              href={Routes.staff_users_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="users" />}
            />
            <UIDivider />
          </>
        )}
        {!isPermissionManager && (
          <>
            <MenuItemTitle>Resources</MenuItemTitle>
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="My access"
              href={Routes.account_accesses_path({
                g: { active: 'YES' },
              })}
              icon={() => <UIIcon fill={Theme.color.black75} name="events" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Liquid search"
              icon={() => <UIIcon fill={Theme.color.black75} name="search" />}
              href={Routes.staff_liquid_search_path()}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="All campaigns"
              icon={() => <UIIcon fill={Theme.color.black75} name="campaigns" />}
              href={Routes.staff_campaigns_path()}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="All sites"
              href={Routes.staff_sites_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="sites" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="All users"
              href={Routes.staff_users_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="users" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="App Store"
              href={Routes.staff_store_app_actions_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="integration" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Webhooks"
              href={Routes.staff_web_hooks_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="webhook" />}
            />
            <UIDivider />
            <MenuItemTitle>Reports</MenuItemTitle>
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Staff reports"
              href={Routes.staff_reports_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="staffPage" />}
            />
            <HeaderUserMenuItemStaff
              name="A/B tests"
              href={Routes.split_tests_staff_reports_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="split" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Customer health"
              href={Routes.customer_health_staff_reports_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="plusSquared" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Settings changes"
              href={Routes.settings_changes_staff_reports_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="changes" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Email performance"
              href={Routes.staff_email_performances_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="emailDomain" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Focus billing"
              href={Routes.billing_staff_reports_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="pay" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Support scripts"
              href={Routes.staff_support_scripts_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="staffPage" />}
            />
            <UIDivider />
            <MenuItemTitle>Monitoring</MenuItemTitle>
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Offer blasts"
              href={Routes.staff_offer_blasts_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="postEvent" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Sidekiq"
              href={Routes.staff_sidekiq_web_path()}
              iconHoveredColor="#b5141c"
              icon={() => <UIIcon fill={Theme.color.black75} name="sidekiq" />}
            />
            <UIDivider />
            <HeaderUserMenuItemStaff
              name="Tables stats"
              href={Routes.staff_tables_stats_path()}
              icon={() => <UIIcon fill={Theme.color.black75} name="staffPage" />}
            />
          </>
        )}
      </UIMenuDropdownStyleOverride>
    )
  }
}
