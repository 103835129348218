/**
 * @prettier
 */

import PT from 'prop-types'

import { Arrow, SubHeaderContainer, SubHeaderInner, SubHeaderLink } from '../index'
import {
  checkPathNameIncludesLink,
  shouldBeActiveReportsLink,
  shouldBeActiveCampaignsNavLink,
} from '../../helpers'

export class SubHeaderConversion extends React.PureComponent {
  static propTypes = {
    isSitePersisted: PT.bool.isRequired,
    isReadRole: PT.bool.isRequired,
    currentSiteCachedSlug: PT.string.isRequired,
    locationRouter: PT.object,
  }

  render() {
    const { isSitePersisted, isReadRole, currentSiteCachedSlug, locationRouter } =
      this.props

    return (
      isSitePersisted &&
      isReadRole && (
        <SubHeaderContainer data-testid="ac-subheader-conversion-container">
          <div className="App-container-inner">
            <SubHeaderInner>
              <div className="flex align-items-center">
                <SubHeaderLink
                  isActive={
                    location.pathname ===
                    Routes.conversion_site_path(currentSiteCachedSlug)
                  }
                  href={Routes.conversion_site_path(currentSiteCachedSlug)}
                  locationRouter={locationRouter}
                >
                  Dashboard
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={shouldBeActiveCampaignsNavLink(
                    currentSiteCachedSlug,
                    'conversion',
                  )}
                  href={Routes.campaigns_site_path(currentSiteCachedSlug, 'conversion')}
                  locationRouter={locationRouter}
                >
                  Campaigns
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_conversion_metrics_aggregation_path(
                      currentSiteCachedSlug,
                    ),
                  )}
                  href={Routes.site_conversion_metrics_aggregation_path(
                    currentSiteCachedSlug,
                  )}
                >
                  Metrics
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_conversion_offers_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_conversion_offers_path(currentSiteCachedSlug)}
                >
                  Offers
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_conversion_rewards_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_conversion_rewards_path(currentSiteCachedSlug)}
                >
                  Rewards
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_conversion_purchases_path(currentSiteCachedSlug),
                  )}
                  href={Routes.site_conversion_purchases_path(currentSiteCachedSlug)}
                >
                  Purchases
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_coupon_lists_path(currentSiteCachedSlug, 'conversion'),
                  )}
                  href={Routes.site_coupon_lists_path(
                    currentSiteCachedSlug,
                    'conversion',
                  )}
                >
                  Coupon Lists
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={checkPathNameIncludesLink(
                    Routes.site_customer_emails_path(currentSiteCachedSlug, 'conversion'),
                  )}
                  href={Routes.site_customer_emails_path(
                    currentSiteCachedSlug,
                    'conversion',
                  )}
                >
                  Emails
                </SubHeaderLink>
                <SubHeaderLink
                  className="mll"
                  isActive={shouldBeActiveReportsLink(
                    currentSiteCachedSlug,
                    'conversion',
                  )}
                  href={Routes.site_reports_path(currentSiteCachedSlug, 'conversion')}
                >
                  All reports
                  <Arrow fill="#555" name="backArrow" />
                </SubHeaderLink>
              </div>
            </SubHeaderInner>
          </div>
        </SubHeaderContainer>
      )
    )
  }
}
