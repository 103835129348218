/**
 * @prettier
 */

import { Link } from 'react-router-dom'
import { UIIcon } from 'talkable-ui-kit'

export class HeaderHomeLink extends React.PureComponent {
  render() {
    const { locationRouter, urlHome } = this.props

    return (
      <div className="logo">
        {locationRouter ? (
          <Link to={urlHome}>
            <UIIcon name="logo" />
          </Link>
        ) : (
          <a href={urlHome}>
            <UIIcon name="logo" />
          </a>
        )}
      </div>
    )
  }
}
