/**
 * @prettier
 */

import PT from 'prop-types'
import styled, { css } from 'styled-components'
import { Theme, UIIcon, UILink } from 'talkable-ui-kit'
import { Link } from 'react-router-dom'

export const Arrow = styled(UIIcon)`
  transform: rotate(180deg);
  margin-right: 0;
  margin-left: 8px;
`

export const VerticalDivider = styled.div`
  height: 32px;
  width: 1px;
  background-color: ${Theme.color.black25};
`

export const BetaLabel = styled.small`
  ${Theme.fontWeights.medium}
  text-transform: uppercase;
  font-size: 10px;
  transform: translate(2px, -5px);
  letter-spacing: 0.03em;
  display: inline-block;
`

const iconFillStyles = color => css`
  .sign-svg-fill-outer {
    fill: ${color};
  }
`
const getActiveStyle = type => {
  switch (type) {
    case 'referral':
      return iconFillStyles('#ff684a')
    case 'emailCapture':
      return iconFillStyles(Theme.color.coral)
    case 'customerServicePortal':
      return iconFillStyles(Theme.color.yellow)
    case 'loyaltyBadge':
      return iconFillStyles(Theme.color.rose)
    default:
      return css`
        position: relative;
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 3px;
          background-color: ${Theme.color.orange};
        }
      `
  }
}

const HeaderLinkStyleOverride = styled(UILink)`
  && {
    ${Theme.fontWeights.medium}
    color: #555555;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: -0.44px;
    transition: color 0.3s;
    height: ${({ height }) => height};
    line-height: ${({ height }) => height};
    display: inline-block;
    white-space: nowrap;

    &:visited {
      color: #555555;
    }
    span {
      display: inline-block;
      transform: translateY(2px);
    }
    .sign-svg-fill-outer {
      transition: 0.3s;
    }

    &.is-active,
    &:active,
    &:hover,
    &:focus {
      ${({ type }) => getActiveStyle(type)};
      color: ${Theme.color.black100};
      .sign-svg-fill {
        fill: ${Theme.color.black100};
      }
    }
  }
`

export class HeaderLink extends React.PureComponent {
  static propTypes = {
    isActive: PT.bool,
    href: PT.string.isRequired,
    height: PT.string,
    type: PT.string,
  }

  static defaultProps = {
    height: 'auto',
    isActive: false,
  }

  render() {
    const { children, isActive, height, type, href, className, locationRouter, target } =
      this.props

    return (
      <HeaderLinkStyleOverride
        className={classNames(className, { 'is-active': isActive })}
        type={type}
        to={locationRouter ? href : undefined}
        href={!locationRouter ? href : undefined}
        height={height}
        as={locationRouter ? Link : undefined}
        target={target}
      >
        {children}
      </HeaderLinkStyleOverride>
    )
  }
}

export const SubHeaderLink = ({ height, children, ...rest }) => {
  return (
    <HeaderLink height="48px" {...rest}>
      {children}
    </HeaderLink>
  )
}

export const HeaderTopContainer = styled.div`
  position: relative;
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
`

export const HeaderTopInner = styled.div`
  position: relative;
  &.is-wide-report {
    @media (min-width: 1600px) {
      padding-right: 50px;
    }
  }
  @media (max-width: 1350px) {
    padding-right: 50px;
  }
`

export const SubHeaderContainer = styled.div`
  background: #fafafa;
  box-shadow:
    0 1px 1px 0 rgba(0, 0, 0, 0.1),
    0 1px 4px 0 rgba(0, 0, 0, 0.05);
`

export const SubHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HeaderContainer = styled.div`
  background-color: #fff;
  margin-bottom: 24px;
  position: relative;
  &.is-wide-report {
    @media (min-width: 1600px) {
      border-bottom: none;
      min-width: 1260px;
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: -20px;
        right: -20px;
        bottom: -1px;
        height: 1px;
        background-color: #eee;
      }
      ${HeaderTopContainer}, ${SubHeaderContainer} {
        margin-right: -20px;
        padding-right: 20px;
        padding-left: 20px;
        margin-left: -20px;
      }
    }
  }
`
