/**
 * @prettier
 */

import Select, { components, StylesConfig, Props } from 'react-select'
import { Theme, UIIcon } from 'talkable-ui-kit'

type ReactSelectOptionType = {
  label: string
  value: string
}

type ReactSelectIsMultiType = false

const Control = (props): JSX.Element | null => {
  return props.selectProps.isSearchable ? <components.Control {...props} /> : null
}

const DropdownIndicator = (): JSX.Element => <UIIcon name="searchLite" />

const selectStyles: StylesConfig<ReactSelectOptionType, ReactSelectIsMultiType> = {
  control: (styles, state) => {
    return {
      ...styles,
      margin: '12px',
      boxShadow: state.isFocused
        ? 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6)'
        : 'none',
      borderRadius: 0,
      borderColor: state.isFocused ? 'rgba(82, 168, 236, 0.8)' : '#ccc',
    }
  },
  menu: (...args) => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)', minWidth: 240 }),
  menuList: styles => ({
    ...styles,
    borderTop: `1px solid ${Theme.color.black15}`,
    padding: 0,
  }),
  option: (styles, { isFocused, isSelected, data, selectProps }) => {
    return {
      ...styles,
      paddingTop: 12,
      paddingBottom: 12,
      color: isSelected ? 'red' : Theme.color.black100,
      borderBottom: '1px solid #eee',
      background:
        data.value === selectProps.value || isFocused ? 'rgb(251, 246, 228)' : 'unset',
      cursor: 'pointer',
      ':active': {
        backgroundColor: '#fbf6e4',
      },
    }
  },
  noOptionsMessage: styles => ({
    ...styles,
    padding: 12,
  }),
  groupHeading: styles => ({
    ...styles,
    textTransform: 'capitalize',
    fontSize: 14,
    borderBottom: '1px solid #eee',
    marginBottom: 0,
    paddingTop: 8,
    paddingBottom: 8,
  }),
  group: styles => ({ ...styles, padding: 0 }),
  indicatorsContainer: styles => ({
    ...styles,
    order: -1,
    marginLeft: 7,
    marginRight: -7,
  }),
}

export const SelectWithSeparateSearch = (props: Props): JSX.Element => (
  <Select
    autoFocus
    backspaceRemovesValue={false}
    components={{
      DropdownIndicator,
      Control,
      IndicatorSeparator: null,
    }}
    controlShouldRenderValue={false}
    hideSelectedOptions={false}
    isClearable={false}
    styles={selectStyles}
    tabSelectsValue={false}
    {...props}
  />
)
